import React, {useContext, useEffect, createRef,} from "react";
import axios from 'axios';
import { useHistory } from 'react-router-dom';

// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components

// core components
import { Switch, Redirect } from "react-router-dom";
import { useGlobalState } from "hooks/useGlobalState";

// other components
import PerfectScrollbar from "perfect-scrollbar";
import {defaultReportConfig} from "../variables/InitState";
import AdminSection from "components/AdminSection";

let ps;
const Admin = props => {
  let history = useHistory();
  const mainPanel = createRef(); // ref to help us initialize PerfectScrollbar on windows devices
  let state = useContext(useGlobalState.State)
  const dispatchState = useContext(useGlobalState.Dispatch)


  useEffect(() => {
    document.querySelector('#root').classList.add('admin-layout');
    return () => document.querySelector('#root').classList.remove('admin-layout');
  }, []);

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      dispatchState({ type: 'root-state', payload: { adminNavigationMobile: false } })
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  /* eslint-disable */
  useEffect(() => {
    // fetch user data and set in the state if it not set already
    function fetchData() {
      let currency = 'USD';
      if (Object.keys(state.signedInUser.data).length === 0 && state.signedInUser.token) {
        dispatchState({ type: 'root-state', payload: { fetching: true } });
        const customerEmail = (window.location?.search || '')?.split("=")?.[1];  //get customer email from GET params
        axios.post(
          process.env.REACT_APP_API_URL + 'user/get',
          { 
            "cukey": state.signedInUser.cukey, 
            customerEmail,
          },
          { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } }
        ).then(response => {
          if (response && response.data && response.status === 200) {
            // this is an existing user, set the user details in the state
            // loop through the subscriptions and get the relevant one
            const allPlans = [...state.calcumateCustomPlans, ...state.calcumatePlans, ...state.calcumatePlansOld];
            let selectedPlan = allPlans.filter(plan => {
              if (response.data.user.isCustomPricingPlan && plan.thePlan === "Custom") {
                return true
              }
              if (plan.sidAUD && plan.sidAUD.includes(response.data.user.lastChosenSubscription)) {
                currency = 'AUD';
                return true;
              }
              if (plan.sidGBP && plan.sidGBP.includes(response.data.user.lastChosenSubscription)) {
                currency = 'GBP';
                return true;
              }
              if (plan.sidEUR && plan.sidEUR.includes(response.data.user.lastChosenSubscription)) {
                currency = 'EUR';
                return true;
              }
              return plan.sidUSD.includes(response.data.user.lastChosenSubscription);
            });
            let selectedPlanOrigin = allPlans.filter(plan => {
              if (response.data.user.isCustomPricingPlan && plan.thePlan === "Custom") {
                return true
              }
              if (plan.sidAUD && plan.sidAUD.includes(response.data.user.lastChosenSubscription)) {
                currency = 'AUD';
                return true;
              }
              if (plan.sidGBP && plan.sidGBP.includes(response.data.user.lastChosenSubscription)) {
                currency = 'GBP';
                return true;
              }
              if (plan.sidEUR && plan.sidEUR.includes(response.data.user.lastChosenSubscription)) {
                currency = 'EUR';
                return true;
              }
              return plan.sidUSD.includes(response.data.user.lastChosenSubscription);
            });

            const thePrices = selectedPlan?.[0]?.[currency];

            const sids = selectedPlan?.[0]?.[`sid${currency}`];
            let currentPlanLabel = 'cancelled';
            if (selectedPlan?.[0] && selectedPlan[0].thePlanLabel) {
              currentPlanLabel = selectedPlan[0].thePlanLabel;
            } else if (selectedPlan?.[0]) {
              currentPlanLabel = selectedPlan[0].thePlan;
            }

            const currentPlan = selectedPlan[0] ? selectedPlan[0].thePlan : 'cancelled';
            dispatchState({
              type: 'root-state',
              payload: {
                currentPlan,
                currentPlanLabel,
                serviceNumber: response.data.user.serviceNumber,
                originServiceNumber: response.data.user.serviceNumber,
                service: response.data.user.service,
                adminMode: {
                  cuid: response.data.user.cuid,
                  cuemail: response.data.user.customerEmail,
                },
                locations: response.data.user.locations,
                originCurrentPlan: selectedPlanOrigin?.[0]?.thePlan ? selectedPlanOrigin?.[0]?.thePlan : currentPlan,
                currentPlanPrice: selectedPlan[0] ? thePrices[sids.indexOf(response.data.user.lastChosenSubscription)] : 0,
                currentPlanPriceRec: sids?.indexOf(response.data.user.lastChosenSubscription) === 1 ? "/mo" : "/yr",
                originPlanPriceRec: sids?.indexOf(response.data.user.lastChosenSubscription) === 1 ? "/mo" : "/yr",
                originPricingPlan: response.data.user.lastChosenSubscription,
                originCurrency: currency,
                showInactiveMessage: !response.data.user.active,
                fetching: false,
                highlightedPlan: state.calcumatePlans.findIndex(plan => plan.thePlan === selectedPlan?.[0]?.thePlan),
                currency,
                signedInUser: {
                  ...state.signedInUser, data: response.data
                },
                reportConfig: response.data.user.reportConfig || defaultReportConfig,
                subscriptionFeatures: state.subscriptionFeatures,
                geoMethod: response.data.user.geoMethod || 'manual'
              }
            });
          } else {
            // redirect to the login page and delete the user details from the state
            dispatchState({ type: 'root-state', payload: { isLoggedIn: false, fetching: false, signedInUser: { token: '', cukey: '', data: {} } } });
            history.replace('/login');
            window.location.reload();
          }
        }).catch(error => {
          console.error(error);
          // redirect to the login page and delete the user details from the state
          dispatchState({ type: 'root-state', payload: { isLoggedIn: false, fetching: false, signedInUser: { token: '', cukey: '', data: {} } } });
          history.replace('/login');
          //window.location.reload();
        })

      }
    }
    fetchData();
    if (navigator.platform.indexOf("Win") > -1 && mainPanel?.current !== null) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1 && ps?.destroy) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };


  }, []);
  /* eslint-enable */

  return (
    <div>
      {state.isLoggedIn && state.signedInUser.token !== '' ?
          <AdminSection {...props} />
        : <Switch>
          <Redirect path from="/admin/my-calcumate" to="/login" />
          <Redirect path from="/admin" to="/login" />
        </Switch>
      }
    </div >
  );
}

export default Admin
