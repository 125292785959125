import React, {useContext, useMemo, useState} from 'react';
import axios from "axios";
//Material UI
import {Grid, Button, makeStyles, Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
//Other components
import AdminHeading from "../../components/AdminHeading/AdminHeading";
import {useGlobalState} from "../../hooks/useGlobalState";
import {mapPlansLimitations} from "../../variables/InitState";
import RestrictedOption from "../../components/PricingPlanLimitation/RestrictedOption";
//Styles
import styles from "assets/jss/material-dashboard-react/components/adminHeadingStyle"
const useStyles = makeStyles(styles);

const defaultReport = [
  {
    'Calculator name': "Calculator name",
    Date: "Date",
    Time: "Time",
    URL: "Domain",
    'Unit ID Recommended': "Recommended unit",
    items: "Selected Items list"
  },
  {
    'Calculator name': "Another Calculator name",
    Date: "Date",
    Time: "Time",
    URL: "Domain",
    'Unit ID Recommended': "Recommended unit",
    items: "Selected Items list"
  },
];

export default props => {
  const classes = useStyles();
  const [fetching, setFetching] = useState(false);
  const state = useContext(useGlobalState.State);
  const restrictions = useMemo(() => mapPlansLimitations?.[state.currentPlan] || [], []);
  const getReport = async event => {
    event.preventDefault();

    try {
      try {
        setFetching(true);
        let data;
        let fileName;
        if (!restrictions.includes('generationReports')) {
          fileName = `(Default) Report-${state.signedInUser.data.user.email}`
          data = {data: {data: defaultReport}};
        } else {
          data = await axios.post(
            process.env.REACT_APP_API_URL + 'customer-report',
            {
              cukey: state.signedInUser.cukey,
              customerEmail: state?.adminMode?.cuemail,
            },
            { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } });
          fileName = `Report-${state.signedInUser.data.user.email}`
        }
        import("export-from-json").then(({default: exportFromJSON}) => {
          const exportType = exportFromJSON.types.csv;
          exportFromJSON({
           data: (data?.data?.data || []),
           fileName,
           exportType
          });
        })
        setFetching(false);
      } catch (e) {
        setFetching(false);
        console.log('Error:', e)
      }
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  const isExportDisabled = useMemo(() => {
    return !Object.keys(state.reportConfig).filter(key => state.reportConfig[key]).length;
  }, [
    state.reportConfig,
  ]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={9} xm={10} md={10} lg={10}>
          <AdminHeading
            overline="DASHBOARD"
            heading="Reporting"
            results={false}
            inMain={true}
            editable={false}
          />
          {
            !restrictions.includes('generationReports') && (
              <div style={{marginTop: 15, marginBottom: 15}}><RestrictedOption /></div>
            )
          }
          <Typography variant="subtitle2" component={'h2'}>See how many people are using the calculator on your website, with this spreadsheet.</Typography>
        </Grid>
        <Grid item xs={9} xm={10} md={10} lg={10} style={{paddingTop: 5}}>
          <Button
            variant="contained"
            className={classes.buttonSpacing}
            color="secondary"
            size="large"
            disabled={isExportDisabled}
            style={{maxWidth: 245}}
            onClick={getReport}
            fullWidth>{fetching ?
            <CircularProgress color='inherit' size={15} /> :
            <><SaveRoundedIcon color='inherit' size={15} style={{marginRight: 10}} />EXPORT CSV REPORT!</>}</Button>
        </Grid>
      </Grid>
    </>
  )
}
