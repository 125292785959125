import axios from "axios";
import { handleUnits } from '../helpers/handleUnits';
import { getDomain } from "./getDomain";
import * as Sentry from "@sentry/react";
import {emailRegex, regexpLocalhost, regexp, isFreePlan} from '../helpers/validation';
import {preparePresets, prepareCategories} from './common';
export const addUnitOnlyUniq = (value, array) => {

  const index = array.indexOf(value)
  if (index === -1) {
    array.push(value)
    return array
  }
  array.splice(index, 1)
  return array;
}
export async function saveIntegration(state, isDetails, dispatchState) {
  // prepare data to be saved in the database
  const domain = getDomain(state.integrationData.integrationDomain);
  const domain2 = regexpLocalhost.test(state.integrationData.integrationDomain2) || regexp.test(state.integrationData.integrationDomain2) ?  getDomain(state.integrationData.integrationDomain2) : null;
  let integrationLoadDetailsEmails = state.integrationData.integrationLoadDetailsEmails || [];
  let integrationLoadingCustomerEmails = state.integrationData.integrationLoadingCustomerEmails || [];

  if (!state.integrationData.integrationItemsLoaded) { // HOTFIX of reseting selected items
    console.error('Saving failed');
    return
  } 
  if (
    state.integrationData.integrationLoadDetailsEmail &&
    !integrationLoadDetailsEmails.includes(state.integrationData.integrationLoadDetailsEmail)
  ) {
    integrationLoadDetailsEmails = [...integrationLoadDetailsEmails, state.integrationData.integrationLoadDetailsEmail];
  }

  if (state.integrationData.integrationLoadingCustomerEmail && !integrationLoadingCustomerEmails.includes(state.integrationData.integrationLoadingCustomerEmail)) {
    integrationLoadingCustomerEmails = [...integrationLoadingCustomerEmails, state.integrationData.integrationLoadingCustomerEmail];
  }

  const measurementSystemIndex =
    state.integrationData.integrationMeasurement === "m" ? 0 : 1;
  const units = handleUnits(state.integrationData.integrationUnits, measurementSystemIndex, domain);
  const uuid = (await import('uuid')).default;
  const integrationData = {
    backgroundStyle: state.integrationData.integrationActiveBgStyle,
    font: state.integrationData.integrationActiveFont,
    language: state.integrationData.integrationActiveLanguage,
    buttonColour: state.integrationData.integrationButtonColour,
    buttonTextColour: state.integrationData.integrationButtonTextColour,
    ctaLabel: state.integrationData.integrationCTALabel,
    ctaTarget: state.integrationData.integrationCTATarget,
    ctaType: state.integrationData.integrationCTAType ? "phoneNo" : "URL",
    cta: state.integrationData.integrationCtaURL,
    ctaShouldIncludeUnitQuantity: state.integrationData.integrationIncQty,
    ctaShouldIncludeUnitID: state.integrationData.integrationIncID,
    domain,
    domain2,
    gradientOne: state.integrationData.integrationGradientOne,
    gradientTwo: state.integrationData.integrationGradientTwo,
    measurement: state.integrationData.integrationMeasurement,
    name: state.integrationData.integrationName,
    primaryColour: state.integrationData.integrationPrimaryColour,
    primaryTextColor: state.integrationData.integrationPrimaryTextColor,
    unitColor: state.integrationData.integrationUnitColor,
    secondaryColour: state.integrationData.integrationSecondaryColour,
    storageType: state.integrationData.integrationStorageType,
    units,
    presets: preparePresets(state),
    categories: prepareCategories(state),
    itemLibraryCustomNames: prepareItemLibraryCustomNames(
      state.integrationData.itemLibrary
    ),
    itemLibrarySizes: prepareItemLibrarySizes(
      state.integrationData.itemLibrary
    ),
    itemLibraryWeight: prepareItemLibraryWeight(state.integrationData.itemLibrary),
    integrationLogo: state.integrationData.integrationLogo,
    showResultUnitDetails: state.integrationData.showResultUnitDetails,
    hideResultUnitDetails: state.integrationData.hideResultUnitDetails,
    integrationSecondaryText: state.integrationData.integrationSecondaryText,
    integrationPrimaryText: state.integrationData.integrationPrimaryText,
    loadDetails: state.integrationData.integrationLoadDetails,
    loadDetailsActive: state.integrationData.integrationLoadDetailsActive,
    loadDetailsEmail: state.integrationData.integrationLoadDetailsEmail,
    id: state.integrationData.id ? state.integrationData.id : uuid.v4(),
    disablePresets: state.integrationData.integrationDisablePresets,
    disableMultipleResults: state.integrationData.integrationDisableMultipleResults,
    loadingCustomerEmail: state.integrationData.integrationLoadingCustomerEmail,
    showLoadingPlanButton: state.integrationData.showLoadingPlanButton,
    integrationUrl: state.integrationData.integrationUrl,
    allowAnimationControl: state.integrationData.integrationAllowAnimationControl,
    loadGenerationForm: state.integrationData.integrationLoadGenerationForm,
    loadingPlanFields: state.integrationData.integrationLoadingPlanFields,
    presetsLabel: state.integrationData.integrationPresetsLabel,
    presetColor: state.integrationData.integrationPresetColor,
    presetBackground: state.integrationData.integrationPresetBackground,
    showItemWeight: state.integrationData.integrationShowItemWeight,
    loadDetailsEmails: integrationLoadDetailsEmails.filter(email => emailRegex.test(email)),
    loadingCustomerEmails: integrationLoadingCustomerEmails.filter(email => emailRegex.test(email)),
    displaySearch: state.integrationData.integrationDisplaySearch,
    copy: state.integrationData.integrationCopy,
    unitImage: state.integrationData.integrationUnitImage,
    customUnitImageEnabled: state.integrationData.integrationCustomUnitImageEnabled,
    CTARedirection: state.integrationData.integrationCTARedirection,
    addCustomItems: state.integrationData.integrationAddCustomItems,
    itemsListMode: state.integrationData.integrationItemsListMode,
    amountMultipleUnit: state.integrationData.integrationAmountMultipleUnit,
    allowCopy: state.integrationData.integrationAllowCopy,
    presetMenuPosition: state.integrationData.integrationPresetMenuPosition,
    permanentCustom: state.integrationData.integrationPermanentCustom,
    selectedItemsIntoURL: state.integrationData.integrationSelectedItemsIntoURL,
    hideCopyright: state.integrationData.integrationHideCopyright,
    hideIsometricGrid: state.integrationData.integrationHideIsometricGrid,
    categoryButtonBorderColor: state.integrationData.integrationCategoryButtonBorderColor,
    ctaShouldIncludeUnitUsedVolume: state.integrationData.integrationIncUsedVolume,
    ctaShouldIncludeUnitVolume: state.integrationData.integrationIncVolume,
    ctaShouldIncIDQuantityMultiple: state.integrationData.integrationCtaShouldIncludeUnitQuantity,
    itemTextColour: state.integrationData.integrationItemTextColour,
    itemTextFontSize: state.integrationData.integrationItemTextFontSize,
    isEnabledNearestFacility: state.integrationData.isEnabledNearestFacility,
  };
  if ('integrationPublished' in state.integrationData) {
    integrationData.published = window.location.search === '?step=confirm' || window.location.search === '?step=confirmed' ?
      true :
      state.integrationData.integrationPublished
  }

  if ('integrationShowUnitsNote' in state.integrationData) {
    integrationData.showUnitsNote = state.integrationData.integrationShowUnitsNote;
  }

  const selectedIntegration = state.integrationDataOptions?.selectedIntegration ? state.integrationDataOptions.selectedIntegration : 0;

  let integrations = state.signedInUser.data.user.integrations ? state.signedInUser.data.user.integrations : []
  if (integrations?.[selectedIntegration]) integrations[selectedIntegration] = integrationData
  else integrations.push(integrationData)


  let userData = {
    ...state.signedInUser.data.user,
    integrations: integrations,
    email: state.signedInUser.data.user.email.toLowerCase(),
    serviceNumber: state.serviceNumber,
    service: state.service,
  };

  if (state.locations) {
    userData.locations = state.signedInUser.data.user.locations;
  }


  if (!state.signedInUser.data.user.stripeAccount && state?.userData?.billing?.paymentMethod) {
    userData.billing = {
      firstName: state.userData.billing.firstName,
      lastName: state.userData.billing.lastName,
      address: state.userData.billing.address,
      city: state.userData.billing.city,
      postal_code: state.userData.billing.postal_code,
      company: state.userData.billing.company,
      country: state.userData.billing.country,
      paymentMethodId: state.userData.billing.paymentMethod.id,
      coupon: state.userData.billing.coupon,
      billingEmail: state.userData.billing.billingEmail,
    }
  }

  if (isDetails && !isFreePlan(state)) {
    userData.paymentDetails = true
  }
  delete userData.stripeAccount //Prevent removing
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + state.signedInUser.token,
  };

  // call the API endpoint and save user data along with the integration
  try {
    const response = await axios.put(
      process.env.REACT_APP_API_URL + "user/" + state.signedInUser.data.user.id,
      { userData },
      { headers }
    );

    if (response.status === 200 && response?.data.status) {
      // if the response was a success, return user details
      if (dispatchState) {
        let payload = {
          originPricingPlan: state.signedInUser?.data?.user?.lastChosenSubscription,
          originServiceNumber: state.serviceNumber,
          signedInUser: {
           ...state.signedInUser,
           data: {
             ...state.signedInUser.data,
             user: {
               ...state.signedInUser.data.user,
               integrations,
               billing: userData.billing,
               stripeAccount: (response?.data?.res?.subscriptionId && response?.data?.res?.customerId) ? {
                subscriptionId: response?.data?.res?.subscriptionId,
                customerId: response?.data?.res?.customerId
               } : state.signedInUser.data.user.stripeAccount
             }
           }
          }
        };
        if (
          response?.data?.res?.cardDetails
        ) {
          payload.signedInUser.data.card = response?.data?.res?.cardDetails;
        }
        dispatchState({
          type: 'root-state',
          payload: payload
        });
        dispatchState({
          type: "integration-data-options",
          payload: {
            selectedIntegration: selectedIntegration,
          },
        });
      }
      return { 
        status: true, 
        integrationData
      };
    } else {
      Sentry.captureMessage(`Saving in integration error: ${JSON.stringify(response)}`);
      return { status: false };
    }
  } catch (error) {
    console.error(error);
    handleError(error);
    Sentry.captureException(error);
    return { status: false, error };
  }
}

export const handleError = (error) => {
  switch (error?.response?.status) {
    case 401: 
      setTimeout(() => {
        window.history.go('/login')
      }, 2000);
    break;  
  }
}

const prepareItemLibraryCustomNames = (itemLibrary) =>
  itemLibrary
    .filter((item) => item.customName)
    .map((item) => ({
      id: item.id,
      customName: item.customName,
    }));

const prepareItemLibrarySizes = (itemLibrary) =>
  itemLibrary
    .filter((item) => item.scale)
    .map((item) => ({
      id: item.id,
      scale: item.scale,
    }));

const prepareItemLibraryWeight = (itemLibrary) =>
  itemLibrary
    .filter((item) => item.scaleWeight)
    .map((item) => ({
      id: item.id,
      scale: item.scaleWeight,
    }));
