import React from "react";
import {Grid} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MiniStorageCalculatorSection1 from '../../assets/img/partners/section-1-background.svg';
import MiniStorageLogo from '../../assets/img/partners/ministorage-logo.svg';
import CalcumateLogo from '../../assets/img/cm-logotype.svg';
import { blueColor, secondaryColours, whiteColor } from "assets/jss/material-dashboard-react";
import Section2 from "./MiniStorage/Section2";
import Details from "./Details";
import BecomePartner from "./BecomePartner";
import Footer from "./Footer";
import Section3 from "./MiniStorage/Section3";
import HomeHeader from "components/HomeHeader/HomeHeader";

const styles = theme => ({
  wrap: {
    backgroundColor: 'white',
  },
  title: {
    textAlign: 'center',
    fontFamily: 'futura-pt',
    fontSize: 48,
    fontWeight: 700,
    lineHeight: '56px',
    color: blueColor,
  },
  HighlightedTitle: {
    color: secondaryColours[0]
  },
  logos: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 48,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.down('sm')]: {
      gap: 24,
    },
    '& img': {
      maxHeight: 108,
      width: 'auto',
      flex:'1 1 auto',
      maxWidth: 348,
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      }
    }
  },
  mainTitle: {
    textAlign: 'center',
    fontSize: 48,
    fontWeight: 700,
    lineHeight: '56px',
    color: blueColor,
    marginBottom: 48,
    '& span': {
      color: secondaryColours[0]
    }
  },
  section1: {
    padding: 'calc(100vw *.077) 32px',
    marginTop: 86,
  },
  detailsWrap: {
    paddingLeft: 24,
    paddingRight: 24,
  }
});

const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();

  return (
    <Grid style={{backgroundColor: whiteColor}}>
      <HomeHeader onScrollHeader />
      <Grid style={{background: `url("${MiniStorageCalculatorSection1}")`}} className={classes.section1}>
        <Grid className={classes.mainTitle}>
          <span >Mini Storage Calculator</span> is<br/> now part of Calcumate
        </Grid>
        <Grid className={classes.logos}>
          <img src={MiniStorageLogo} alt="MiniStorageLogo" />
          <img src={CalcumateLogo} alt="Calcumate Logo"/>
        </Grid>
      </Grid>
      <Section2 />
      <Section3 />
      <div className={classes.detailsWrap}>
        <Details hideDetailsTop />
      </div>
      <BecomePartner />
      <Footer />
    </Grid>
  );
}