import React from "react";
import StoragelyHomePageImage from '../../../assets/img/partners/storagely-home-page.png';

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Button } from "@material-ui/core";
import { roseColor, secondaryColours, primaryColour, whiteColor } from "assets/jss/material-dashboard-react";

//roseColor
const styles = theme => ({
  wrap: {
    maxWidth: 1184,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 'calc(100vw * .02)',
    paddingBottom: 'calc(100vw * .023)',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 32,
      paddingRight: 32,
      paddingTop: 24,
      paddingBottom: 80,
    }
  },
  mainTypography: {
    fontSize: 16,
    color: secondaryColours[0],
    fontWeight: 500
  },
  name: {
    marginBottom: 16,
    marginTop: 8,
    fontSize: 32,
    lineHeight: '32px',
    fontWeight: 'medium',
    fontWeight: 500
  },
  descriptions: {
    fontSize: 24,
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '16px',
    }
  },
  leftColumn: {
    paddingRight: 24,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 'unset',
    }
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 24,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 'unset',
    },
    '& img': {
      marginLeft: 'auto',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginTop: 16
      }
    }
  },
  exploreButton: {
    backgroundColor: roseColor[4],
    marginTop: 20,
    color: whiteColor,
    padding: '16px 24px',
    '& span': {
      textTransform: 'none',
      fontSize: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      }
    },
    '&.MuiButton-root:hover': {
      backgroundColor: roseColor[5]
    }
  },
  aboutContainer: {
    backgroundColor: primaryColour['50'],
    marginBottom: 102,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 88,
    }
  }
});

const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();

  const handleLinks = (e, path, target = '_blank') => {
    e.preventDefault();
    window.open(path, target).focus();
  }

  return (
    <Grid container className={classes.aboutContainer}>
      <Grid container className={classes.wrap} justifyContent="space-between" alignItems="center">
        <Grid item md={6} xs={12} style={{marginRight: 'auto', alignSelf: 'flex-start', marginTop: 20}} className={classes.leftColumn}>
          <Typography component={'h3'} className={classes.mainTypography}>ABOUT</Typography>
          <Typography component={'h2'} className={classes.name}>Storagely</Typography>
          <Typography component={'p'} className={classes.descriptions}>
            Storagely leads the storage industry to rent differently. As the most innovative all-in-one self-storage website solution, Storagely guarantees an increase in rentals through its user-friendly platform, setting a new standard for how self-storage facilities operate and engage with their customers. 
            For more information visit 
            <a style={{color: roseColor[4], paddingLeft: 5}} href="https://storagely.io/" target="_blank">www.storagely.io</a>
          </Typography>
        </Grid>
        <Grid item md={6} xs={12} className={classes.rightColumn}>
          <img src={StoragelyHomePageImage} alt="storagely home page"/>
          <Button onClick={event => handleLinks(event, 'https://storagely.io/calcumate/')} className={classes.exploreButton}>Explore Partner</Button>
        </Grid>
      </Grid>
    </Grid>
  );
}