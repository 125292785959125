import React from "react";
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import StoragelyLogoColored from '../../../assets/img/partners/storagely-logo-colored.png';
import StoragelyHomePage1 from '../../../assets/img/partners/storagely-home-page-1.png';
import StoreganiseColored from '../../../assets/img/partners/storeganise-colored.png';
import StoreganiseHomepage from '../../../assets/img/partners/storeganise-homepage.png';
import StoraColored from '../../../assets/img/partners/stora-colored.png';
import StoraHomepage from '../../../assets/img/partners/stora-homepage.png';
import CheckIcon from "@material-ui/icons/Check";
import { secondaryColours, grayColor, whiteColor, primaryColour, roseColor, primaryColor, } from "assets/jss/material-dashboard-react";
import classNames from "classnames";

const partners = [
  {
    icon: StoragelyLogoColored,
    details: [
      {
        text: 'Storagely was the first to integrate Calcumate as a standard feature of its software. <a href="https://calcumate.co/blog/storagely-first-to-integrate-calcumate-as-standard" target="_blank">Learn more.</a>'
      },
      {
        text: 'For Storagely customers on the Storagely Professional and Enterprise packages, Storagely is a <a href="/embedded-partners" target="_blank">Calcumate Embedded Partner</a> meaning Calcumate is included with Storagely.'
      },
      {
        text: 'For Storagely customers not on a Storagely Professional or Enterprise package, Calcumate is available as an add-on. Chat with your Storagely account manager to learn more.'
      }
    ],
    image: StoragelyHomePage1,
    subTypography: 'ABOUT STORAGELY',
    typographyColor: '#FF6700', 
    buttons: [
      {
        label: 'Explore Partner',
        url:'https://storagely.io/calcumate/',
        styles: {
          hoverColor: roseColor[5],
          backgroundColor: roseColor[4]
        }
      }
    ],
    description: `Storagely leads the storage industry to rent differently. 
      As an innovative all-in-one self-storage website solution, 
      Storagely guarantees an increase in rentals through its user-friendly platform, 
      setting a new standard for how self-storage facilities operate and engage with their customers.
      For more information visit: <a href="https://storagely.io/" target="_blank">www.storagely.io</a>
      `
  },
  {
    icon: StoreganiseColored,
    details: [
      {
        text: 'Storeganise was the first global self-storage software company to integrate Calcumate. <a href="https://calcumate.co/blog/storeganise-partners-with-calcumate" target="_blank">Learn more.</a>'
      },
      {
        text: 'Calcumate is available as an add-on for all Storeganise customers, integrated directly into the Storeganise platform.'
      },
    ],
    image: StoreganiseHomepage,
    subTypography: 'ABOUT STORGANISE',
    typographyColor: '#4188EF',
    buttons: [
      {
        label: 'Explore Partner',
        url:'http://storeganise.com/calcumate',
        styles: {
          hoverColor: secondaryColours[0],
          backgroundColor: primaryColor[6]
        }
      },
      {
        label: 'Request Storeganise Integration',
        url:'https://forms.gle/ZSxvVGd7rDRY68GN9',
        styles: {
          hoverColor: secondaryColours[1],
          backgroundColor: primaryColour['900']
        }
      }
    ],
    description: `
      Storeganise is a leading provider of self-storage, 
      valet storage and mobile storage software solutions with customers on six continents.
      For more information visit: <a href="https://storeganise.com/" target="_blank">www.storeganise.com</a>
      `
  },
  {
    icon: StoraColored,
    details: [
      {
        text: 'Stora and Calcumate partnered to increase rentals for self-storage operators in the UK, across Europe and beyond. Learn more.'
      },
      {
        text: 'Calcumate is available as an add-on for all Stora customers, integrated directly into the Stora platform.'
      },
    ],
    image: StoraHomepage,
    subTypography: 'ABOUT STORA',
    typographyColor: '#FF6700', 
    buttons: [
      {
        label: 'Explore Partner',
        url:'https://stora.co/integrations/calcumate/',
        styles: {
          hoverColor: roseColor[5],
          backgroundColor: roseColor[6]
        }
      },
      {
        label: 'Request Stora Integration',
        url:'https://stora.co/get-started',
        styles: {
          hoverColor: secondaryColours[0],
          backgroundColor: primaryColour['900']
        }
      }
    ],
    description: `
      Stora is a UK-based provider of property management software for the global self-storage industry. 
      The Stora platform enables self-storage operators to manage their facilities and tenants seamlessly and efficiently, 
      from unit rentals and payments to tenant communications and analytics.
      For more information visit: <a href="https://stora.co/integrations" target="_blank">www.stora.co</a>
    `
  }
];

const styles = theme => ({
  checkMarkIcon: {
    fontSize: '24px!important',
    marginRight: 8,
    marginBottom: 'auto',
    color: secondaryColours[0],
    lineHeight: '28px'
  },
  detailWrap: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontFamily: 'Futura Round',
    fontWeight: 400,
    fontSize: 24,
    lineHeight: '28px',
    color: grayColor[9],
    '& p': {
      fontSize: 24,
      lineHeight: '28px'
    }
  },
  subTypography: {
    marginBottom: 40,
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '28px',
    [theme.breakpoints.down('sm')]: {

    }
  },
  details: {
    fontSize: 24,
    lineHeight: '28px',
    color: '#FF8533'
  },
  even: {
    backgroundColor: whiteColor
  },
  odd: {
    backgroundColor: primaryColour['50']
  },
  container: {
    maxWidth: 1312,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  partnerWrap: {
    paddingTop: 104,
    paddingBottom: 48,
    paddingTop: 'calc(100vw * 0.072)',
    paddingBottom: 'calc(100vw * .034)',
    paddingLeft: 32,
    paddingRight: 32,
    [theme.breakpoints.down('sm')]: {
      padding: '80px 32px'
    }
  },
  leftColumn: {
    paddingRight: 20,
    maxWidth: 602,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 'unset',
    }
  },
  rightColumn: {
    paddingLeft: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 32,
    paddingTop: 30,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 'unset',
      paddingTop: 'unset',
    },
    '& img': {
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }
    }
  },
  iconWrap: {
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
    },
    [theme.breakpoints.down('md')]: {
      '& img': {
        margin: 'auto',
        maxWidth: 300,
        width: '100%'
      }
    }
  },
  point: {
    marginBottom: 40
  },
  button: {
    color: whiteColor,
    maxWidth: 395,
    width: '100%',
    padding: '16px 44px',
    textTransform: 'none',
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    '&:hover': {
      backgroundColor: 'var(--hover-backgroundColor)!important'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 24px',
    }
  },
  buttonsWrap: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginTop: 40,
      '& .MuiButton-label': {
        fontSize: 16,
        textTransform: 'capitalize'
      }
    }
  },
  description: {
    fontSize: 24,
    lineHeight: '28px',
    [theme.breakpoints.down('md')]: {
      marginBottom: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    '& a': {
      color: 'var(--color)!important'
    }
  }
});

const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();

  const handleLinks = (e, path, target = '_blank') => {
    e.preventDefault();
    window.open(path, target).focus();
  }
  console.log('classes', classes);
  return (
    <>
      {
        partners.map((partner, index) => {
          return (
            <Grid container key={index} className={classNames({
              [classes.odd]: index % 2 !== 0,
              [classes.even]: index % 2 === 0,
            }, classes.partnerWrap)}>
              <Grid container className={classes.container}>
                <Grid item xs={12} md={6} className={classes.leftColumn}>
                  <Grid container>
                    <Grid item className={classes.iconWrap}><img src={partner.icon} alt={partner.subTypography} /></Grid>
                    <Grid item>
                      <Grid container>
                        {
                          partner.details.map((detail, index2) => {
                            return (
                              <Grid item key={index2} className={classes.point}>
                                <div className={classes.detailWrap}>
                                  <CheckIcon
                                    fontSize="small"
                                    className={classes.checkMarkIcon}
                                  />
                                  <Typography dangerouslySetInnerHTML={{__html: detail.text}} />
                                </div>
                              </Grid>
                            );
                          })
                        }
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography component={'h3'} style={{color: partner.typographyColor}} className={classes.subTypography}>
                        {
                          partner.subTypography
                        }
                      </Typography>
                    </Grid>
                    <Grid 
                      item 
                      className={classes.description} dangerouslySetInnerHTML={{__html: partner.description}} 
                      style={{
                        '--color': partner.typographyColor
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} className={classes.rightColumn}>
                  <img src={partner.image} alt={partner.description} />
                  <Grid className={classes.buttonsWrap}>
                    {
                      partner.buttons.map(button => {
                        return (
                          <Button
                            onClick={event => handleLinks(event, button.url)}
                            style={{
                              backgroundColor: button.styles.backgroundColor,
                              '--hover-backgroundColor': button.styles.hoverColor
                            }}
                            className={classes.button}
                            variant="contained"
                          >{button.label}</Button>
                        );
                      })
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }) 
      }
    </>
  );
}
