import React, { Fragment } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"

// Core components

// Other components
import ContactForm from "components/ContactForm/ContactForm"
import AdminTransactionalHeader from "../../components/AdminTransactionalHeader/AdminTransactionalHeader"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
const useStyles = makeStyles(styles)

const ContactUs = props => {
  const classes = useStyles()
  return (
    <Fragment>
      <AdminTransactionalHeader
        homeBtn={() => props.history.push("/")}
        backBtn={null}
        logo={true}
        closeBtn={null}
      />
      <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
        <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
          <Typography variant="h1" className={classes.headerOneLine}>Contact us</Typography>
          <ContactForm />
        </Grid>
      </Grid>
    </Fragment>

  );
}

export default ContactUs
