import React from 'react';
import { makeStyles } from '@material-ui/core';

const styles = {
  loaderWrap: {
    textAlign: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    top: 0,
    left: 0,
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100,
  },
  "@keyframes animation": {
    '0%': {opacity: 1},
    '100%': {opacity:1},
    '50%': {opacity:.5},
  },
  loaderRunning: {
    display: 'inline-block',
    position: 'relative',
    width: 64,
    height: 64,
    '& > div': {
      position: 'absolute',
      width: 13,
      height: 13,
      borderRadius: '50%',
      backgroundColor: 'var(--color)',
      animation: 'lds-grid 1.2s linear infinite',
      animationName: '$animation',
    },
    '& > div:nth-child(1)': {
      top: 6,
      left: 6,
      animationDelay: '0s',
      animationName: '$animation',
    },
    '& > div:nth-child(2)': {
      top: 6,
      left: 26,
      animationDelay: '-0.4s',
      animationName: '$animation',
    },
    '& > div:nth-child(3)': {
      top: 6,
      left: 45,
      animationDelay: '-0.8s',
      animationName: '$animation',
    },
    '& > div:nth-child(4)': {
      top: 26,
      left: 6,
      animationDelay: '-0.4s',
      animationName: '$animation',
    },
    '& > div:nth-child(5)': {
      top: 26,
      left: 26,
      animationDelay: '-0.8s',
      animationName: '$animation',
    },
    '& > div:nth-child(6)': {
      top: 26,
      left: 45,
      animationDelay: '-1.2s',
      animationName: '$animation',
    },
    '& > div:nth-child(7)': {
      top: 45,
      left: 6,
      animationDelay: '-0.8s',
      animationName: '$animation',
    },
    '& > div:nth-child(8)': {
      top: 45,
      left: 26,
      animationDelay: '-1.2s',
      animationName: '$animation',
    },
    '& > div:nth-child(9)': {
      top: 45,
      left: 45,
      animationDelay: '-1.6s',
      animationName: '$animation',
    }
  }
}
const useStyles = makeStyles(styles);

const LoaderDots = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.loaderWrap}>
      <div className={classes.loaderRunning} style={{'--color': props.primaryColour || '#c00000'}}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoaderDots;
