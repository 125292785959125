import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

import CalcumateLogo from '../../assets/img/cm-isotype.png';

const styles = theme => ({
  wrap: {
    backgroundColor: '#00437C',
    padding: '19px 16px'
  },
});

const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.wrap}>
      <Grid item xs={12}>
        <img src={CalcumateLogo} alt="Calcumate Logo" />
      </Grid>
    </Grid>
  );
}