import React from "react";
import { Typography, Grid, } from "@material-ui/core";
import FillStorage from '../../assets/img/partners/fill-storage.png';
import EasySetup from '../../assets/img/partners/easy-setup.png';
import StreamlinedOperations from '../../assets/img/partners/streamlined-operations.png';
import BoostConversion from '../../assets/img/partners/boost-conversion.png';
import LeadData from '../../assets/img/partners/lead-data.png';
import { makeStyles } from "@material-ui/core/styles";
import { grayColor, blueColor, secondaryColours, primaryColour } from '../../assets/jss/material-dashboard-react';
import CheckIcon from '@material-ui/icons/Check';
import classNames from 'classnames';

const features = [
  {
    icon: FillStorage,
    typography: 'Simplify Size Selection and Keep Customers On-Site',
    points: [
      {
        text: 'Offer a user-friendly, interactive size calculator to your web visitors.'
      },
      {
        text: 'Prospective customers can quickly and easily determine which size they need.'
      },
      {
        text: 'Reduces the chances that prospective customers find the answer to “which size do I need?” from one of your competitors.'
      }
    ]
  },
  {
    icon: EasySetup,
    typography: 'Easy, Hassle-Free Setup',
    points: [
      {
        text: 'After your Calcumate account is setup, just have your web team add a simple code snippet for each calculator.'
      },
      {
        text: 'Need help? Calcumate and your provider are always ready to assist.'
      },
    ]
  },
  {
    icon: StreamlinedOperations,
    typography: 'Streamlined Operations Beyond Your Website',
    points: [
      {
        text: 'Let customers determine their space requirements independently, freeing your sales team to focus on closing deals.'
      },
      {
        text: 'Give your contact centre a powerful tool to engage prospects and capture their details for follow up: “Would you like me to send you an animation showing how your items will fit in, and how full your unit will be?”'
      },
    ]
  },
  {
    icon: BoostConversion,
    typography: 'Boost Conversion Rates & Maximise Marketing ROI',
    points: [
      {
        text: 'Reduces friction in the booking process with fast, precise and credible size recommendations.'
      },
      {
        text: 'Keeps visitors on-site and engaged with a fun, interactive tool that guides them smoothly towards checkout.'
      },
      {
        text: 'No more frustrating back-and-forth— customers move forward with their size selection to the next stage in the booking journey, reducing abandonment.'
      },
    ]
  },
  {
    icon: LeadData,
    typography: 'Capture High-Value Lead Data for Smarter Marketing',
    points: [
      {
        text: 'Collect rich lead information including item lists, number of bedrooms, volume and weight of items, unit size recommendations, and more.'
      },
      {
        text: 'Lead data is exposed via email and programmatically, ready to feed into your website, CRM or other marketing system for personalised follow-ups and smarter segmentation.'
      },
      {
        text: 'Unlock powerful possibilities: send tailored quotes, score leads, personalise communications, and you could even auto-generate packing lists.'
      },
    ]
  }
];

const styles = theme => ({
  checkMarkIcon: {
    fontSize: '24px!important',
    marginRight: 8,
    marginBottom: 'auto',
    color: secondaryColours[0]
  },
  detailWrap: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 16
  },
  typography: {
    color: blueColor,
    fontSize: 32,
    lineHeight: '40px',
    marginBottom: 40,
    fontWeight: 500
  },
  subTypography: {
    fontWeight: 500,
    color: blueColor,
    marginTop: 60,
    fontSize: 32,
    lineHeight: '40px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginTop: 15,
      lineHeight: '20px',
    },
    '& span': {
      fontWeight: 400,
    }
  },
  featureTypography: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 500,
    marginBottom: 16,
    minHeight: 66
  },
  featurePoint: {
    fontSize: 24,
    lineHeight: '24px',
    color: grayColor[12]
  },
  container: {
    maxWidth: 1392,
    backgroundColor: primaryColour['50'],
    borderRadius: 32,
    margin: 'auto',
    padding: '104px 88px',
    [theme.breakpoints.down('md')]: {
      padding: '48px 32px',
    }
  },
  pointsWrap: {
    paddingTop: 50,
    justifyContent: 'center',
    gap: 64
  },
  pointIcon: {
    marginBottom: 8
  },
  pointWrap: {
    marginTop: 56,
    maxWidth: '29%',
    width: 400,
    [theme.breakpoints.down('md')]: {
      maxWidth: '44%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    }
  },
  detailsWrap: {
    maxWidth: 569,
    '& p': {
      fontWeight: 500,
      fontSize: 24,
    },
    '& span': {
      fontWeight: 400,
      fontSize: 24,
    }
  },
  point: {
    fontSize: 24,
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    }
  },
  pullDetailsSubTypographyRight: {
    marginLeft: 'auto'
  },
  hideDetailsTop: {
    display: 'none'
  }
});

const useStyles = makeStyles(styles);

export default ({
  hideDetailsTop,
}) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classNames(classes.detailsWrap)}>
          <Typography
            component={'h2'}
            className={classNames(classes.typography, {
              [classes.hideDetailsTop]: hideDetailsTop,
            })}
          >Turn More Browsers Into Renters with Calcumate</Typography>
          <Typography
            component={'p'}
            className={{
              [classes.hideDetailsTop]: hideDetailsTop,
            }}
          >
            Many size guides are clunky, force customers to guess which unit they need and/or force them to backtrack before they can go forward and rent a unit. 
            <span>
              {' '}Calcumate changes that by delivering a seamless, interactive experience that drives conversions - and captures valuable data to give you a competitive edge 
            </span>
          </Typography>
          <Typography component={'p'} className={classNames(classes.subTypography)}>
            Key benefits of adding Calcumate<br/> to your offerings
          </Typography>
        </Grid>
        <Grid container className={classes.pointsWrap}>
            {
              features.map((feature, index) => {
                return (
                  <Grid  item key={index} className={classes.pointWrap}>
                    <Grid container>
                      <Grid item xs={12} className={classes.pointIcon}>
                        {
                          <img src={feature.icon} alt={feature.typography} />
                        }
                      </Grid>
                      <Grid item className={classes.featureTypography} xs={12}>
                        {
                          feature.typography
                        }
                      </Grid>
                      <Grid item xs={12}>
                        {
                          feature.points.map((elem, index2) => {
                            return (
                              <Grid item key={index2}>
                                <div className={classes.detailWrap}>
                                  <CheckIcon
                                    fontSize="small"
                                    className={classes.checkMarkIcon}
                                  />
                                  <Typography className={classes.point} dangerouslySetInnerHTML={{__html: elem.text}} />
                                </div>
                              </Grid>
                            );
                          })
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })
            }
        </Grid>
      </Grid>
    </Grid>
  )
}