import React, {useContext, useEffect, useMemo, useState} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import {
  Grid,
  Button,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Fab,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Popover,
  Switch, Select
} from "@material-ui/core"
import EditRounded from '@material-ui/icons/EditRounded';
import DeleteForeverRounded from '@material-ui/icons/DeleteForeverRounded';
import HelpRounded from "@material-ui/icons/HelpRounded";
import Alert from '@material-ui/lab/Alert';
// Core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import { saveIntegration } from '../../../helpers/SaveIntegration';
import UnpaidModal from '../../FreeVersion/Modal';
import EditField from '../../../components/EditField';

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import classNames from "classnames";
import {mapPlansLimitations} from "../../../variables/InitState";
import PayButton from "../../FreeVersion/Button";
import ColourPickerField from "../../../components/ColourPickerField/ColourPickerField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(styles)

const PresetsStep = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const restrictions = useMemo(() => mapPlansLimitations?.[state.currentPlan] || [], []);
  const [openDialog, setOpenDialog] = useState(false) //local state
  const [deleteItem, setDeleteItem] = useState(null) //local state
  const [presetPop, setPresetPop] = useState(null);
  const [edit, setEdit] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const { theRef, updating, setUpdating } = props

  const cleanState = {
    ID: 0,
    preName: "",
    itemsAssigned: [],
    qtyAssigned: []
  }

  const presetsAmount = useMemo(() => {
    const sorted = state.integrationData.integrationPresets.sort((a, b) => a.ID > b.ID);
    return sorted.length ? (sorted?.[sorted.length - 1]?.ID || sorted.length) : 0
  },[state.integrationData.integrationPresets.length]);

  const editPre = ID => () => {
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationIsNewHelper: false,
        integrationSecPanel: true,
        integrationEditing: ID,
        integrationNewPreset: { ...state.integrationData.integrationPresets[ID] }
      }
    })
  }

  useEffect(() => {
    if (firstLoad) return setFirstLoad(false);
    const timeout = setTimeout(async () => {
      setUpdating(true);
      try {
        await saveIntegration({ ...state, integrationData: { ...state.integrationData } }, false, dispatchState);
      } catch (e) {
        console.log('Saving error:', e)
      }
      setUpdating(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [
    state.integrationData.integrationPresetBackground,
    state.integrationData.integrationPresetColor,
    state.integrationData.integrationPresetMenuPosition
  ]);

  const handleDisablePresets = async () => {
    setUpdating(true)
    dispatchState({type: 'integration-data', payload: {integrationDisablePresets: !state.integrationData.integrationDisablePresets}});
    await saveIntegration({ ...state, integrationData: { ...state.integrationData, integrationDisablePresets: !state.integrationData.integrationDisablePresets }}, false, dispatchState);
    setUpdating(false)
  }

  const createPre = () => () => {
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: true,
        integrationIsNewHelper: true,
        integrationNewPreset: { ...cleanState, ID: presetsAmount + 1 }
      }
    })
  }

  const deletePre = () => async () => {
    setOpenDialog(false) //Close modal
    setUpdating(true)
    let arrayToChange = state.integrationData.integrationPresets;
    const update = (await import("immutability-helper")).default;
    //Save new
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: false,
        integrationPresets: update(arrayToChange, { $splice: [[deleteItem, 1]] }),
        integrationNewPreset: { ...cleanState }
      }
    });

    await saveIntegration({ ...state, integrationData: { ...state.integrationData, integrationSecPanel: false, integrationPresets: update(arrayToChange, { $splice: [[deleteItem, 1]] }), integrationNewPreset: { ...cleanState } } }, false, dispatchState);
    setUpdating(false)
  }

  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'integration-data', payload: { integrationStepperStep: 3 } }) // Set stepper step number 4
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */

  const handleOpenDialog = index => () => {
    setOpenDialog(true);
    setDeleteItem(index);
  }

  return (
    <form
      noValidate
      autoComplete="off"
      className={
        classNames(classes.standardForm, {[classes.disabledBright]: !restrictions.includes('managePresets'), [classes.pointerEventNone]: !restrictions.includes('managePresets') })}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          {
            edit ?
              <Grid style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', width: '100%'}}>
                <EditField
                  property={'integrationPresetsLabel'}
                  label={'Presets label'}
                  closeEdit={() => setEdit(false)}
                />
                <Grid item xs={12} className={classes.colorsWrap} style={{width: '100%', marginTop: 30}}>
                  <Divider className={classes.customDividerOne} />
                  <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Label colours</Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <ColourPickerField
                        value={state.integrationData.integrationPresetColor || ''}
                        onChange={color => {
                          dispatchState({
                            type: 'integration-data',
                            payload: {
                              integrationPresetColor: color } }) }}
                        id="p-text-color"
                        label="Text colour"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ColourPickerField
                        value={state.integrationData.integrationPresetBackground || ''}
                        onChange={color => {
                          dispatchState({
                            type: 'integration-data',
                            payload: {
                              integrationPresetBackground: color } }) }}
                        id="p-background-color"
                        label="Background colour"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{width: '100%', marginTop: 15}}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="p-integration-language">Menu Position</InputLabel>
                        <Select
                          labelId="presets-position-select-outlined-label"
                          id="presets-position-select-outlined-label"
                          value={state.integrationData.integrationPresetMenuPosition || 'first'}
                          onChange={(event) => dispatchState({
                            type: 'integration-data',
                            payload: { integrationPresetMenuPosition: event.target.value }
                          })}
                          label="Presets position"
                          name={'Presets position'}
                          variant="outlined"
                        >
                          <MenuItem value={'first'}>First</MenuItem>
                          <MenuItem value={'last'}>Last</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> :
              (
                <>
                  <>
                    <Typography variant="h5" component="h1">
                      {
                        state.integrationData.integrationPresetsLabel || 'Presets'
                      }
                      <HelpRounded
                        style={{ verticalAlign: "sub", fontSize: "1.3rem" }}
                        onClick={(event) => { setPresetPop(event.currentTarget) }}
                      />
                    </Typography>
                    <Popover
                      id="domainURL"
                      open={Boolean(presetPop)}
                      anchorEl={presetPop}
                      onClose={() => { setPresetPop(null) }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Typography variant="body2" className={classes.blueText}>Presets are pre-programmed lists of furniture items so the user can quickly calculate how much space they need. We’ve recommended presets based on the number of the bedrooms in a house although you are welcome to edit these if you prefer. <br /><br />Please note you can add a minimum of 1 and a maximum of 5 presets to each Calcumate integration due to display limitations. Otherwise, the "next" and "add preset" buttons will be disabled.</Typography>
                    </Popover>
                    <Tooltip title="Edit integration" aria-label="Edit integration" style={{marginLeft: 10, marginRight: !restrictions.includes('integrationPresetsLabel') ? 0 : 'auto'}}>
                      <Fab
                        color="primary"
                        aria-label="Edit"
                        size="small"
                        onClick={() => {
                          setEdit(true);
                        }}
                        disabled={!restrictions.includes('integrationPresetsLabel')}
                      >
                        <EditRounded />
                      </Fab>
                    </Tooltip>
                    {
                      !restrictions.includes('integrationPresetsLabel') && (
                        <div style={{marginRight: 'auto'}}>
                          <PayButton setUpdating={props.setUpdating} feature={'integrationPresetsLabel'} updating={updating} />
                        </div>
                      )
                    }
                  </>
                  <Grid item>
                    <Switch
                      checked={!state.integrationData.integrationDisablePresets}
                      onChange={handleDisablePresets}
                      color="primary"
                      name="disable-presets"
                      inputProps={{ 'aria-label': 'disable presets' }}
                    />
                  </Grid>
                </>
              )
          }
        </Grid>
        {/* <Grid item xs={6} style={{textAlign:"right"}}>
          <Typography variant="overline" style={{textTransform:"initial"}}>{state.integrationData.integrationPresets.length} of 5 presets</Typography>
        </Grid> */}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} className={ state.integrationData.integrationDisablePresets ? classes.disabled : classes.enabled}>
          <TableContainer>
            <Table className={classes.integrationTable} aria-label="Preset setup">
              <TableBody>
                {state.integrationData.integrationPresets.length ? state.integrationData.integrationPresets.map((prop, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">
                      <Typography variant="subtitle2" component="p">{prop.preName}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Edit preset" aria-label="Edit preset">
                        <Fab
                          color="primary"
                          aria-label="Edit preset"
                          size="small"
                          onClick={editPre(index)}
                        >
                          <EditRounded />
                        </Fab>
                      </Tooltip>
                      <Tooltip title="Delete preset" aria-label="Delete preset">
                        <Fab
                          color="primary"
                          aria-label="Delete preset"
                          size="small"
                          onClick={handleOpenDialog(index)}
                          className={classes.deleteIconColour}
                        >
                          <DeleteForeverRounded />
                        </Fab>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )) : <TableRow>{!restrictions.includes('managePresets') && <TableCell align="center"><Alert severity="error">Please add a preset to continue. </Alert></TableCell>}</TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {
          !restrictions.includes('managePresets') && (<UnpaidModal />)
        }
        <Grid item xs={12} className={ state.integrationData.integrationDisablePresets ? classes.disabled : classes.enabled}>
          <Button
            variant="contained"
            className={classes.buttonSpacing}
            color="secondary"
            size="medium"
            fullWidth
            onClick={createPre()}
            disabled={
              state.integrationData.integrationPresets.length >= 5 ||
              !restrictions.includes('presetChange')
            }
          >Add preset</Button>
        </Grid>
      </Grid>
      {/* Closing dialog */}
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Preset will be deleted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={() => { setOpenDialog(false) }} color="primary">No</Button>
          <Button size="large" onClick={deletePre()} color="primary">Yes</Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default PresetsStep
