import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { grayColor } from '../../../assets/jss/material-dashboard-react';
//images
import StoragelyLogoColored from '../../../assets/img/partners/storagely-logo-colored.png';
import RiseTrendIcon from '../../../assets/img/partners/rise-trend-icon.png';
import DecreaseCost from '../../../assets/img/partners/decrease-cost.png';
import CoinPig from '../../../assets/img/partners/coin-pig.png';
import FillStorage from '../../../assets/img/partners/fill-storage.png';

const benefits = [
  {
    icon: RiseTrendIcon,
    typography: 'Book Units Faster and Boost Conversions',
    text: `Calcumate's accurate size calculator, combined with Storagely's smooth rental system, helps customers find the right storage unit quickly and confidently, driving higher rental conversions.`
  },
  {
    icon: CoinPig,
    typography: 'Save Time and Money on Customer Support',
    text: `By guiding customers to the right unit size on their own, Calcumate reduces support inquiries and eliminates costly errors like size-related unit swaps.`

  },
  {
    icon: DecreaseCost,
    typography: 'Get Powerful Tools at No Extra Cost',
    text: `Calcumate is included for free in Storagely's Professional and Enterprise packages, delivering high value without added expense.`

  },
  {
    icon: FillStorage,
    typography: 'Fill Your Storage Units Quickly',
    text: `With Calcumate's integration, more website visitors turn into paying tenants, giving your facility a competitive edge and increasing occupancy.  `
  },
];

const styles = theme => ({
  wrap: {
    maxWidth: 1184,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 112,
    paddingBottom: 164.5,
    paddingLeft: 32,
    paddingRight: 32,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 35,
    }
  },
  logo: {
    marginBottom: 32
  },
  detailsWrap: {
    maxWidth: 640,
    margin: 'auto',
    textAlign: 'center',
    color: grayColor[12],
    lineHeight: '24px',
    '& a': {
      color: grayColor[12],
      lineHeight: '24px',
    },
    marginBottom: 64
  },
  benefitIcon: {
    marginBottom: 8
  },
  benefitName: {
    marginBottom: 24,
    fontWeight: 500,
    maxWidth: 225,
    '& h3': {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '24px'
    },
  },
  text: {
    fontSize: 24,
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    }
  },
  benefitWrap: {
    marginTop: 36
  },
  partnerDescription: {
    color: '#21272A',
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '24px'
  }
});

const useStyles = makeStyles(styles);


export default () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.wrap}>
      <Grid container className={classes.detailsWrap}>
        <Grid container justifyContent='center' className={classes.logo}>
          <img src={StoragelyLogoColored} alt="storagely logo colored" />
        </Grid>
        <Grid item>
          <Typography component={'p'} className={classes.partnerDescription}>
            Storagely offers Calcumate as a standard inclusion with its Professional and Enterprise packages, delivering powerful tools to optimize rental performance.  
            <br/>(Storagely is also a <a href={'/add-on-partners'} target='_blank'>Calcumate Add-On Partner</a>). 
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        {
          benefits.map((elem, index) => {
            return (
              <Grid item key={index} sm={6} className={classes.benefitWrap}>
                <Grid container style={{maxWidth: 455}}>
                  <Grid item sm={12} className={classes.benefitIcon}>
                    <img src={elem.icon} alt={elem.typography} />
                  </Grid>
                  <Grid item sm={12} className={classes.benefitName}>
                    <Typography component={'h3'}>{elem.typography}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography className={classes.text} component={'p'}>{elem.text}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        }
      </Grid>
    </Grid>
  );
}