import {
  whiteColor,
  primaryColour,
  blueColor
} from "assets/jss/material-dashboard-react.js"

const homeHeaderStyle = theme => ({
  headerBackground: {
    backgroundColor: 'transparent',
    color: whiteColor,
    position: 'fixed',
    top: 0,
    zIndex: 200,
    width: "100%",
    margin: "0 auto",
  },
  whiteBackground: {
    backgroundColor: whiteColor,
  },
  headerMenuWrap: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    gap: 15,
    marginRight: 160,
    [theme.breakpoints.down(1040)]: {
      gap: 8,
      marginRight: 144,
    },
    [theme.breakpoints.up("lg")]: {
      gap: 30,
    },
  },
  headerMenuLink: {
    color: primaryColour[900],
    cursor: 'pointer',
  },
  isHeaderOn: {
    marginRight: 165,
  },
  resetHoverLink: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  userIconsWrap: {
    borderRadius: '50%',
    width: 36,
    height: 36,
    display: 'inline-block',
    cursor: 'pointer'
  },
  userIcon: {
    width: '100%',
  },
  onScrolling: {
    "& > .MuiGrid-container": {
      backgroundColor: whiteColor,
      paddingTop: 15,
      paddingRight: 25,
      paddingBottom: 13,
      paddingLeft: 25,
      boxShadow: "0 2px 20px rgba(0,0,0,0.1)",
      [theme.breakpoints.up("md")]: {
        paddingTop: 15,
        paddingRight: 15,
        paddingBottom: 13,
        paddingLeft: 20,
      },
    },
    '& .header-conteiner': {
      height: 86,
    }
  },
  headerItemLogo: {
    position: 'absolute',
    top: 37,
    transition: 'all .5s'
  },
  headerItemLogoHeadeOn: {
    top: 21,
  },
  tableMode: {
    height: '123px!important',
    '& .header-logo': {
      top: 15,
    }
  },
  headerContainer: {
    width: "100%",
    margin: "0 auto",
    padding: 25,
    transition: "all 0.4s",
    height: 120,
    [theme.breakpoints.up("md")]: {
      padding: '30px 20px',
    },
  },
  standardLink: {
    textTransform: "uppercase",
    textDecoration: "none",
    paddingTop:10,
    paddingBottom:10,
    display: "inline-block",
    fontSize: "0.875rem",
    fontFamily: "futura-pt, sans-serif",
    fontWeight: 500,
    lineHeight: 1.57,
    "&:hover": {
      color:primaryColour[200] + "!important"
    },
    [theme.breakpoints.down(1040)]: {
      '& svg': {
        marginLeft: 0 + '!important'
      }
    }
  },
  whiteLink: {
    color: whiteColor + "!important",
  },
  primaryLink: {
    color: blueColor + "!important",
  },
  primaryBackground: {
    backgroundColor: blueColor + "!important",
  },
  whiteLogo: {
    filter: "brightness(2000%)"
  },
  withSeparator: {
    "&:after": {
      display:"inline-block",
      content:`" "`,
      width: 1,
      height: 17,
      backgroundColor: whiteColor,
      margin:"0 30px",
      verticalAlign: "middle"
    }
  },
  navSeparator: {
    fontWeight: 600,
    display: "inline-block",
    color: primaryColour[900]
  },
  ml3: {
    marginLeft: 30
  },
  ml2: {
    marginLeft: 17
  }
})

export default homeHeaderStyle
