import React from 'react';

import {Button, Grid, Typography, Hidden} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import HomeHeader from "components/HomeHeader/HomeHeader";
import StoragelyEmbedded from './Partners/StoragelyEmbedded';
import About from './Embedded/About';
import { secondaryColours, primaryColour, grayColor, whiteColor, blueColor } from '../../assets/jss/material-dashboard-react';
import BecomePartner from './BecomePartner';
//images
import PeoplesWithPuzzles from '../../assets/img/partners/people-with-puzzles.png';
import Footer from './Footer';
import Details from './Details';

//background: #EFF5F7;
const styles = theme => ({
  wrap: {
    backgroundColor: 'white',
    '& button': {
      cursor: 'pointer'
    }
  },
  section1: {
    backgroundColor: primaryColour['50'],
    marginTop: 86,
    //static
    //paddingTop: 152,
    //paddingBottom: 104,
    //dynamic
    paddingTop: 'calc(100vw *.105)',
    paddingBottom: 'calc(100vw *.072)',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 64,
      paddingBottom: 40,
    },
    paddingLeft: 24,
    paddingRight: 24,
    '& img': {
      width: '100%'
    }
  },
  mainTypography: {
    fontSize: 48,
    color: '#00437C',
    fontWeight: 700,
    lineHeight: '56px',
    '& span': {
      color: secondaryColours[0],
      fontWeight: 700,
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  details: {
    margin: '40px 0',
    fontSize: 24,
    lineHeight: '32px',
    color: grayColor[12],
    paddingRight: 'calc(100vw * .03)',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingRight: 'unset',
    }
  },
  partnerButton: {
    backgroundColor: secondaryColours[0],
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: blueColor,
    },
    '& span': {
      textTransform: 'none',
      fontSize: 18,
      color: whiteColor,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      }
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto', 
      marginRight: 'auto',
      display: 'block'
    }
  }
});

const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();

  const handleLinks = (e, path, target = '_blank') => {
    e.preventDefault();
    window.open(path, target).focus();
  }

  return (
    <Grid container className={classes.wrap}>
      <HomeHeader onScrollHeader />
      <Grid container className={classes.section1}>
        <Grid container style={{
          maxWidth: 1184,
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>
          <Grid item md={6} xs={12}>
            <Typography component={'h1'} className={classes.mainTypography}>Calcumate <span>Embedded Partners</span></Typography>
            <Hidden mdUp>
              <Grid item xs={12} style={{marginTop: 48, marginBottom: 48}}>
                <img src={PeoplesWithPuzzles} alt="peoples with puzzles" />
              </Grid>
            </Hidden>
            <Typography component={'p'} className={classes.details}>
              The following companies are Calcumate Embedded Partners who have embedded 
              Calcumate technology as a standard feature of their offerings.
            </Typography>
            <Button
              onClick={event =>  handleLinks(event, '/contact-us')}
              variant="contained"
              className={classes.partnerButton}>Become a Partner</Button>
          </Grid>
          <Hidden smDown>
            <Grid item sm={6}>
              <img src={PeoplesWithPuzzles} alt="peoples with puzzles" />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <StoragelyEmbedded />
      <About />
      <Details />
      <BecomePartner />
      <Footer />
    </Grid>
  );
}
