import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import SelfUnit from "../../../assets/img/self.webp";
import CheckIcon from "@material-ui/icons/Check";
import {
  blueColor,
  secondaryColours,
  whiteColor,
  transparentBlue,
} from "assets/jss/material-dashboard-react";
import { makeStyles } from "@material-ui/core/styles";

const columns = [
  {
    title: "What’s Happening?",
    points: [
      {
        text:
          "Mini Storage Calculator has been acquired by Calcumate, the world’s leading 3D storage calculator and size guide.",
      },
      {
        text:
          "Existing Mini Storage Calculator customers will be transitioned to Calcumate’s industry-leading platform over the coming weeks.",
      },
      {
        text:
          "The Mini Storage Calculator tool will remain operational during the transition to ensure uninterrupted service.",
      },
    ],
  },
  {
    title: "What’s New with Calcumate?",
    subTitle: "With Calcumate you will:",
    points: [
      {
        text:
          "Gain access to the world’s most advanced 3D storage calculators and size guide.",
      },
      {
        text:
          "Use the size guide / space calculator solution trusted by 5,000+ facilities in 30+ countries.",
      },
      {
        text:
          "Receive dedicated support to transition you to Calcumate with a minimum of effort, and help you get the most from your new storage calculator.",
      },
    ],
  },
];

const styles = (theme) => ({
  pointWrap: {
    marginTop: 56,
    maxWidth: "29%",
    width: 400,
    [theme.breakpoints.down("md")]: {
      maxWidth: "44%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  pointIcon: {
    marginBottom: 8,
  },
  footer: {
    backgroundColor: secondaryColours[0],
    borderRadius: 32,
    maxWidth: 699,
    margin: "auto",
    padding: "32px 69px",
    color: whiteColor,
    fontSize: 24,
    fontWeight: 400,
    lineHeight: "28px",
    "& .hight-lighted": {
      color: blueColor,
      fontSize: 24,
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "28px",
    },
  },
  section2: {
    backgroundColor: whiteColor,
    paddingTop: "calc(100vw *.078)",
    paddingBottom: "calc(100vw *.067)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: 32,
    paddingRight: 32,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    "& h2": {
      textAlign: "left",
      color: blueColor,
      fontSize: 40,
      fontWeight: 500,
      lineHeight: "48px",
      marginBottom: 64,
    },
  },
  checkMarkIcon: {
    fontSize: "24px!important",
    marginRight: 8,
    marginTop: 2,
    marginBottom: "auto",
    color: secondaryColours[0],
  },
  detailWrap: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 32,
  },
  columnsWrap: {
    maxWidth: 1190,
    margin: "auto",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "calc(100vw *.07)",
  },
  column: {
    backgroundColor: transparentBlue,
    borderRadius: 32,
    padding: '48px 64px',
    [theme.breakpoints.down('sm')]: {
      padding: '24px 32px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '32px 24px',
    },
    maxWidth: 686,
    marginBottom: 72,
    "& h3": {
      color: blueColor,
      textAlign: "center",
      fontSize: 24,
      fontWeight: 500,
      lineHeight: "28px",
      marginBottom: 44,
    },
    "& p": {
      fontSize: 24,
      lineHeight: '28px',
    }
  },
  welcomeSection: {
    maxWidth: 1190,
    width: '100%',
    alignItems: "left",
    justifyContent: "space-between",
    paddingLeft: 15,
    paddingRight: 15,
    display: "flex",
    gap: 32,
    "& .left-column": {
      maxWidth: 557,
      fontSize: 24,
      lineHeight: '28px'
    },
    "& img": {
      maxWidth: 301,
    },
  },
  selfUnitWrap: {
    alignSelf: "self-start",
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // maxWidth: '50%',
    // width: '100%'
    // marginLeft: 'auto',
  },
});

const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();

  return (
    <Grid className={classes.section2}>
      <Grid className={classes.welcomeSection}>
        <Grid>
        <Typography component={"h2"}>
          Welcome, Mini Storage
          <br /> Calculator Users!
        </Typography>
          <Typography component={"p"} className="left-column">
            We’re excited to announce that Mini Storage Calculator is now part
            of Calcumate. For over a decade, Mini Storage Calculator has been a
            trusted tool helping storage operators and their customers estimate
            space needs. Now, as part of Calcumate, Mini Storage Calculator
            users get access to an enhanced storage size guide that’s intuitive,
            visually engaging, and designed to increase customer conversions.
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid className={classes.selfUnitWrap}>
            <img src={SelfUnit} alt="Self Unit" />
          </Grid>
        </Hidden>
      </Grid>
      <Grid container className={classes.columnsWrap}>
        {columns.map((column, index) => {
          return (
            <Grid item xs={12} key={index} className={classes.column}>
              <Typography component={"h3"}>{column.title}</Typography>
              {column.subTitle ? (
                <Typography component={"p"}>{column.subTitle}</Typography>
              ) : (
                ""
              )}
              <Grid>
                {column.points.map((point, index2) => {
                  return (
                    <Grid item key={index2}>
                      <div className={classes.detailWrap}>
                        <CheckIcon
                          fontSize="small"
                          className={classes.checkMarkIcon}
                        />
                        <Typography
                          className={classes.point}
                          dangerouslySetInnerHTML={{ __html: point.text }}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
