import * as React from 'react';
import { Slider, Grid, makeStyles} from "@material-ui/core";

export default function SliderSizes({
  value,
  setValue,
  fontFamily,
  label = '',
}) {

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid 
      container 
      className={classes.sizesSlider}
    >
      {label && <span className={classes.label}>{label}</span>}
      <Slider
        size="small"
        value={value}
        aria-label="Small"
        valueLabelDisplay="auto"
        onChange={handleChange}
        max={50}
        min={10}
      />
      <span 
        style={{
          fontSize: value,
          fontFamily
        }}
        className={classes.preview}
      >
        aA
      </span>
    </Grid>
  );
};

const styles = theme => ({
  sizesSlider: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'space-between',
    height: 56,
    alignItems: 'center',
    padding: 14,
    '& .MuiSlider-root': {
      maxWidth: '65%'
    },
    position: 'relative',
  },
  preview: {
    display: 'inline-block',
    width: '30%',
    textAlign: 'center',
    marginLeft: 5
  },
  label: {
    position: 'absolute',
    display: 'inline-block',
    top: -13,
    left: 8,
    padding: '0 3px',
    zIndex: 1,
    background: '#FFF',
    fontSize: '0.75em'
  }
});

const useStyles = makeStyles(styles);