import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { blueColor, secondaryColour, secondaryColours, whiteColor } from "assets/jss/material-dashboard-react";

//00437C
const styles = theme => ({
  wrap: {
    backgroundColor: '#00437C',
    maxWidth: 1025,
    
    margin: '80px auto',
    borderRadius: 32,
    padding: '32px 72px',
    color: whiteColor,
    width: '100%',
    width: 'calc(100% - 7px)',
    [theme.breakpoints.down('sm')]: {
      gap: 34,
      padding: 32,
    }
  },
  typography: {
    fontSize: 32,
    lineHeight: '40px',
    textAlign: 'left',
    marginBottom: 19
  },
  description: {
    fontSize: 24,
    lineHeight: '32px',
    textAlign: 'left'
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      backgroundColor: whiteColor,
      '& *': {
        color: blueColor,
        fontSize: 18,
        lineHeight: '16px',
        fontWeight: 500
      }
    }
  },
  becomePartnerButton: {
    '& span': {
      textTransform: 'none',
      fontSize: 18
    },
    '&.MuiButton-root:hover': {
      backgroundColor: secondaryColours[0],
      '& span': {
        color: whiteColor
      }
    }
  }
});

const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();

  const handleLinks = (e, path, target = '_blank') => {
    e.preventDefault();
    window.open(path, target).focus();
  }

  return (
    <Grid container className={classes.wrap}>
      <Grid item xs={12} md={8}>
        <Typography component={'h3'} className={classes.typography}>
          Ready to Drive More Conversions and Leads for Your Customers? 
        </Typography>
        <Typography component={'p'} className={classes.description}>
          Calcumate isn’t just a size guide. It’s a powerful calculator that drives conversions, 
          delivers data-driven insights, and gives your customers the edge over their competitors.
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} className={classes.buttonWrap}>
        <Button className={classes.becomePartnerButton} onClick={event => handleLinks(event, '/contact-us')} variant="contained" color="primary">Become a partner</Button>
      </Grid>
    </Grid>
  );
}