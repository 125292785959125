import React, { useState, useMemo, useContext, createRef } from 'react';
import Navbar from "components/Navbars/Navbar";
import AdminSidebar from "components/AdminSidebar/AdminSidebar";
import { isMobile } from 'react-device-detect';
import routes from "routes";
import { Button, Typography, Dialog, DialogContent, DialogContentText, DialogActions, makeStyles } from "@material-ui/core";
import logo from "assets/img/calcumate-logo@2x.png";
import { useGlobalState } from "hooks/useGlobalState";
import {mapPlansLimitations,} from "../../variables/InitState";
// core components
import { Switch, Redirect } from "react-router-dom";
// styles
import adminStyle from "assets/jss/material-dashboard-react/layouts/adminStyle";
const useStyles = makeStyles(adminStyle);

export default (props) => {
  const [openDialog, setOpenDialog] = useState(true); //local state
  const { children } = props;
  const mainPanel = createRef(); // ref to help us initialize PerfectScrollbar on windows devices
  let state = useContext(useGlobalState.State)
  const dispatchState = useContext(useGlobalState.Dispatch)

  const handleDrawerToggle = () => {
    dispatchState({ type: 'root-state', payload: { adminNavigationMobile: !state.adminNavigationMobile } })
  };

  const classes = useStyles();

  const restrictions = useMemo(() => mapPlansLimitations?.[state.currentPlan] || [], [state.currentPlan]);

  const activeRoutes = useMemo(() => {
    if (!restrictions.includes('generationReports')) {
      return routes.filter(route => route.name !== "REPORTING");
    }
    return routes;
  }, [state.signedInUser, restrictions]);

  return (
    <div className={classes.wrapper}>
      <AdminSidebar
        routes={activeRoutes}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={state.adminNavigationMobile}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={activeRoutes}
          handleDrawerToggle={handleDrawerToggle}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              <Redirect exact from="/admin" to="/admin/my-account" />
            </Switch>
            {children}
          </div>
        </div>
      </div>
      {isMobile ?
        <Dialog
          open={openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="h5" component="h2" className={classes.textMarginBottom}>Hello. It appears you are using a mobile device. Please switch to a desktop computer as this portal will not display correctly on a mobile device. This will be updated soon. We apologise for any inconvenience. Thank you.</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button size="large" onClick={() => { setOpenDialog(false) }} color="primary">OK</Button>
          </DialogActions>
        </Dialog>
        : ''}
      </div>
  );
}