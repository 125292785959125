import React from "react";
import {Grid, Typography, Button, colors, Hidden} from '@material-ui/core'
import HomeHeader from "components/HomeHeader/HomeHeader";
import { secondaryColours, primaryColour, grayColor, blueColor, whiteColor } from '../../assets/jss/material-dashboard-react';
import { makeStyles } from "@material-ui/core/styles";
import PartnersImage from '../../assets/img/partners/partners.png';
import PartnersList from "./AddOn/PartnersList";
import Details from "./Details";
import BecomePartner from "./BecomePartner";
import Footer from "./Footer";
import classNames from "classnames";

const styles = theme => ({
  wrap: {
    backgroundColor: 'white',
  },
  section1: {
    backgroundColor: primaryColour['50'],
    marginTop: 68,
    paddingBottom: 'calc(100vw * 0.028)',
    paddingTop: 'calc(100vw * 0.05)',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 64,
      paddingBottom: 40,
    },
    paddingLeft: 24,
    paddingRight: 24,
    '& img': {
      width: '100%'
    }
  },
  mainTypography: {
    fontSize: 48,
    color: '#00437C',
    fontWeight: 700,
    lineHeight: '56px',
    '& span': {
      color: secondaryColours[0],
      fontWeight: 700,
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },
  details: {
    margin: '40px 0',
    fontSize: 24,
    lineHeight: '32px',
    color: grayColor[12],
    paddingRight: 'calc(100vw * .03)',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingRight: 'unset',
    }
  },
  partnerTypography: {
    fontSize: 40,
    lineHeight: '40px',
    color: blueColor,
    fontWeight: 500,
    textAlign: 'center',
    margin: 'auto',
  },
  container: {
    maxWidth: 1312,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  partnersTypographyWrap: {
    paddingTop: 144,
    [theme.breakpoints.down('md')]: {
      paddingTop: 80,
    }
  },
  partnerButton: {
    backgroundColor: secondaryColours[0],
    '&:hover': {
      backgroundColor: blueColor,
    },
    '& span': {
      textTransform: 'none',
      fontSize: 18,
      color: whiteColor,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      }
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto', 
      marginRight: 'auto',
      display: 'block'
    }
  }
});

const useStyles = makeStyles(styles);


export default () => {
  const classes = useStyles();

  const handleLinks = (e, path, target = '_blank') => {
    e.preventDefault();
    window.open(path, target).focus();
  }

  return (
    <Grid container className={classes.wrap}>
      <HomeHeader onScrollHeader />
      <Grid container className={classes.section1}>
        <Grid container className={classes.container} alignItems="center">
          <Grid item md={6} xs={12}>
            <Typography component={'h1'} className={classes.mainTypography}>Calcumate <span>Add-On Partners</span></Typography>
            <Hidden mdUp>
              <Grid item xs={12}>
                <img src={PartnersImage} alt="peoples with puzzles" />
              </Grid>
            </Hidden>
            <Typography component={'p'} className={classes.details}>
              Key benefits and companies who have integrated Calcumate as an optional add-on. 
            </Typography>
            <Button onClick={event =>  handleLinks(event, '/contact-us')} variant="contained" className={classes.partnerButton}>Become a Partner</Button>
          </Grid>
          <Hidden smDown>
            <Grid item xs={6}>
              <img src={PartnersImage} alt="peoples with puzzles" />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Grid container className={classNames(classes.container, classes.partnersTypographyWrap)}>
        <Typography component={'h2'} className={classes.partnerTypography}>Calcumate Add-on Partners</Typography>
      </Grid>
      <PartnersList />
      <Details />
      <BecomePartner />
      <Footer />
    </Grid>
  );
}