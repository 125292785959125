import React, {Fragment, useContext, useEffect, useMemo, useRef, useState} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import {Typography, Button, Grid, Fab, Tooltip, Box, Snackbar, Card} from "@material-ui/core"
import AddRounded from '@material-ui/icons/AddRounded';
import EditRounded from '@material-ui/icons/EditRounded'
import FileCopyRounded from '@material-ui/icons/FileCopyRounded'
import DeleteForeverRounded from '@material-ui/icons/DeleteForeverRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ShoppingCartRoundedIcon from "@material-ui/icons/ShoppingCartRounded";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
// core components
import { Link, useHistory } from "react-router-dom";
import { useGlobalState } from "hooks/useGlobalState";

// other components
import AdminHeading from "components/AdminHeading/AdminHeading"
import AdminPanels from "components/AdminPanels/AdminPanels"
import cloneDeep  from 'lodash.clonedeep'
import MoreIntegrations from '../../components/MoreIntegrations';
import {regexp, allowedStorageTypes, regexpLocalhost, isFreePlan} from '../../helpers/validation';
// styles
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle";
import stylesInt from "assets/jss/material-dashboard-react/layouts/integrationStyle";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";

import { generateCode } from '../../helpers/generateCode';
import {integrationsAmount} from "../../variables/InitState";
import {primaryColour} from "../../assets/jss/material-dashboard-react";
import { handleError } from "../../helpers/SaveIntegration"; 
const useStyles = makeStyles(styles);
const useStylesInt = makeStyles(stylesInt);
//Temp solution
const mapCustomLimit = {
  'spencer@budgetstorageandlock.com': 38,
  'frederik.nenadic@locko.be': 23,
  'laiken@madisoncapgroup.com': 300,
  'laurent.lacour@stockerseul.com': 60
};

const MyCalcumate = () => {
  const classes = useStyles();
  const classesInt = useStylesInt();
  const state = useContext(useGlobalState.State);
  const dispatchState = useContext(useGlobalState.Dispatch);
  let history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [updating, setUpdating] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [textToCopy, setTextToCopy] = useState('');
  const [copySuccess, setCopySuccess] = useState('Copy Code');
  const [copiedItem, setCopiedItem] = useState(null)
  const textAreaRef = useRef(null);

  //const integrationLimit = useMemo(() => Number(state.originServiceNumber),[state.currentPlan,state.serviceNumber,]);
  const integrationLimit = useMemo(() => {
    const origin = integrationsAmount[state.currentPlan] || 100;
    return mapCustomLimit?.[state?.signedInUser?.data?.user?.email] || origin;
  }, [
    state.currentPlan,
    state?.signedInUser?.data?.user?.email
  ]);
 
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (key) => {
    dispatchState({
      type: "integration-data-options",
      payload: {
        selectedIntegration: key,
      },
    });
    setOpen(true);
  };

  const copyToClipboard = (item, key) => {
    const integrationCode = generateCode(state, key);

    if (!integrationCode) return
    setTextToCopy(integrationCode)

    setTimeout(() => {
      textAreaRef.current.select();
      document.execCommand('copy');
    }, 1)

    setCopySuccess('Code copied');
    setCopiedItem(key);
    setTimeout(()=>{
      setCopySuccess('Copy Code');
      setCopiedItem(null);
    }, 3000);
  };

  /* eslint-disable */
  useEffect(() => {
    if (state.isLoggedIn && state.signedInUser.token !== '') {
      dispatchState({ type: 'root-state', payload: {
        newIntegration: state.signedInUser.data.user &&
          (!state.signedInUser.data.user.integrations || state.signedInUser.data.user.integrations.length === 0) } });
    } else {
      history.replace('/login');
      window.location.reload();
    }


  }, []);
  /* eslint-enable */

  const updateIntegrations = async (integrations) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.signedInUser.token,
    };
    // call the API endpoint and save user data along with the integration
    try {
      const response = await axios.put(
        process.env.REACT_APP_API_URL + "user/" + state.signedInUser.data.user.id,
        { userData:  {...state.signedInUser.data.user, integrations }},
        { headers }
      );
      if (response.status === 200 && response?.data.status) {
        dispatchState({ type: 'root-state', payload: { signedInUser: { ...state.signedInUser, data: { ...state.signedInUser.data, user: { ...state.signedInUser.data.user, integrations } } } } });

      } else {
        //Handle Error
        setError('Integration has not saved');
      }
    } catch (error) {
      console.error(error);
      handleError(error);
      setError(typeof error === 'string' ? error : 'Internal server Error');
      return { status: false, error };
    }
  }


  const handleCopyCreation = async (item, key) => {
    setUpdating(true)
    dispatchState({
      type: "integration-data-options",
      payload: {
        selectedIntegration: key,
      },
    });
    const newIntegration = cloneDeep(item);
    newIntegration.name = `${newIntegration.name} Copy`;
    newIntegration.copy = true;
    const uuid = (await import('uuid')).default;
    newIntegration.id = uuid.v4();
    const integrations = cloneDeep(state.signedInUser.data.user.integrations)
    integrations.push(newIntegration)
    await updateIntegrations(integrations)
    setUpdating(false)
  }

  const removeIntegration = async () => {
    setRemoving(true);
    const selected = state.integrationDataOptions?.selectedIntegration
    if (typeof selected === 'number') {
      const integrations = cloneDeep(state.signedInUser.data.user.integrations)
      integrations.splice(selected, 1)
      await updateIntegrations(integrations)
    } else {
      setError('Removing failed')
    }
    setOpen(false);
    setRemoving(false);
  }

  const initIntegrationData = (item) => ({
    integrationName: item.name,
    integrationActiveBgStyle: item.backgroundStyle,
    integrationActiveFont: item.font,
    integrationActiveLanguage: item.language,
    integrationButtonColour: item.integrationButtonColour,
    integrationButtonTextColour: item.buttonTextColour,
    integrationCTALabel: item.ctaLabel,
    integrationCTATarget: item.ctaTarget,
    integrationCTAType: item.ctaType === 'phoneNo',
    integrationCtaURL: item.cta,
    integrationDomain: item.domain,
    integrationDomain2: item.domain2,
    integrationGradientOne: item.gradientOne,
    integrationGradientTwo: item.gradientTwo,
    integrationMeasurement: item.measurement,
    integrationPrimaryColour: item.primaryColour,
    integrationUnitColor: item.unitColor,
    integrationSecondaryColour: item.secondaryColour,
    integrationStorageType: item.storageType,
    integrationUnits: item.units,
    integrationPresets: item.presets,
    integrationCategories: item.categories,
    id: item.id,
    integrationHideIsometricGrid: item.hideIsometricGrid,
    isEnabledNearestFacility: item.isEnabledNearestFacility,
  })

  const handleIntegrationEdit = (item, key) => {
    // set the integration record in the state and load the new integration panel
    // map integration data to the state
    dispatchState({
      type: "integration-data-options",
      payload: {
        selectedIntegration: key,
      },
    });

    const integrationData = initIntegrationData(item);

    // update the state
    dispatchState({ type: 'integration-data', payload: {
      ...state.integrationData,
        ...integrationData,
        integrationDomain: integrationData.integrationDomain ? integrationData.integrationDomain : '',
        integrationName: integrationData.integrationName ? integrationData.integrationName : '',
    } });
    // load the new integration panel
    history.push('/admin/my-calcumate/new');
  }

  const createNewIntegration = event => {
    if (state.signedInUser?.data?.user?.integrations?.length >= integrationLimit || state.showInactiveMessage) {
      history.replace('/admin/my-account/my-subscription');
    } else {
      dispatchState({
        type: "integration-data-options",
        payload: {
          selectedIntegration: state.signedInUser.data.user.integrations.length,
        },
      });
      dispatchState({ type: 'root-state', payload: { newIntegration: true } });
      history.push('/admin/my-calcumate/new')
    }
  }

  const isValid = item => {

    //if (!state.signedInUser?.data?.user?.stripeAccount?.subscriptionId) return false
    return (
      state.signedInUser?.data?.user?.stripeAccount?.subscriptionId && (
        regexp.test(item.domain) || 
        regexpLocalhost.test(item.domain)
      ) &&
    item.categories.length &&
    item.measurement &&
    allowedStorageTypes.includes(item.storageType) && (regexp.test(item.cta) || regexpLocalhost.test(item.cta))) ||
    (
      !state.signedInUser?.data?.user?.stripeAccount?.subscriptionId &&
      (
        isFreePlan(state) || 
        state.signedInUser?.data?.user?.lastChosenSubscription === process.env.REACT_APP_STRIPE_STRAGELY_PRICE
      ) &&
      (
        regexp.test(item.domain) || regexpLocalhost.test(item.domain)
      ) &&
      item.categories.length &&
      item.measurement &&
      allowedStorageTypes.includes(item.storageType)
    ) ? true : false;
  }

  return (
    <Fragment>
      <textarea
        style={{position:"absolute", left:-10000}}
        ref={textAreaRef}
        rows={12}
        onChange={()=>{}}
        value={textToCopy} />
      <Grid container spacing={4}>
        <Grid item xs={9} xm={10} md={10} lg={10}>
          <AdminHeading
            overline="DASHBOARD"
            heading={`Calculator Builder`}
            //heading={`Calculator Builder ${state.signedInUser?.data?.user?.integrations ? ` (${state.signedInUser?.data?.user?.integrations?.length}/${integrationLimit})` : ''}`}
            results={false}
            inMain={true}
            editable={false}
          />
        </Grid>
        {
          state.fetching && <Grid item xs={9} xm={10} md={10} lg={10} className={classes.integrationsContainer} style={{marginBottom: 30}}><CircularProgress color='inherit' /></Grid>
        }
        {
          state.signedInUser.data.user?.integrations?.length ? (
            <Grid item container xs={3} xm={2} md={2} lg={2}
                  direction="row"
                  justify="space-between"
                  alignItems="center">
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                style={{marginBottom: 15, paddingRight: 15}}
              >
                <Tooltip title="Create new" aria-label="Create new">
                  <Fab
                    style={{ marginLeft: 0, backgroundColor: '#fff'}}
                    aria-label={'Create new'}
                    size="medium"
                    onClick={createNewIntegration}
                  >
                    {
                      (state.signedInUser?.data?.user?.integrations?.length >= integrationLimit || state.showInactiveMessage) ?
                        <ShoppingCartRoundedIcon style={{marginLeft: 0, color: primaryColour[900] }} />  :
                        <AddRounded style={{marginLeft: 0, color: primaryColour[900]}} />
                    }
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>
          ) : null
        }
      </Grid>
      {
        !state.fetching && (
          <Grid container spacing={4} className={classes.integrationsContainer}>
          { state?.signedInUser?.data?.user?.integrations?.length ?
            (
              <>
                {
                  state.signedInUser.data.user.integrations.map((item, key) => {
                    return (
                      <Grid item xm={12} md={12} lg={12} key={key} style={{flexBasis: 'unset'}} className={{[classesInt.disableStep]: key + 1 > integrationLimit}}>
                        <AdminPanels
                          theHeader={false}
                          headerAction={false}
                          fabCodeAction={() => { alert('Code button') }}
                          fabCloneAction={() => { alert('Clone button') }}
                          fabEditAction={() => { alert('Edit button') }}
                          fabDeleteAction={() => { alert('Delete button') }}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="h6">{item.name}</Typography>
                            </Grid>
                            {
                              key + 1 > integrationLimit ? <Grid item style={{minHeight: 40}}></Grid> : (
                                <Grid item>
                                  { (isValid(item) && !state.showInactiveMessage) && (
                                  <Tooltip title={copySuccess} aria-label={copySuccess}>
                                    <Fab
                                      color="primary"
                                      aria-label={copySuccess}
                                      size="small"
                                      onClick={() => copyToClipboard(item, key)}
                                      //onMouseOver={() => setCopySuccess('Copy Code')}
                                    >
                                      { copiedItem === key ? <CheckRoundedIcon /> : <CodeRoundedIcon />  }
                                    </Fab>
                                  </Tooltip>)
                                  }
                                  {
                                    !state.showInactiveMessage && (
                                        <Tooltip title="Edit integration" aria-label="Edit integration">
                                          <Fab
                                            color="primary"
                                            aria-label="Edit"
                                            size="small"
                                            onClick={() => handleIntegrationEdit(item, key)}
                                          >
                                            <EditRounded />
                                          </Fab>
                                        </Tooltip>
                                    )
                                  }
                                  {
                                    (state.signedInUser?.data?.user?.integrations?.length >= integrationLimit || state.showInactiveMessage) ?
                                      (
                                        <Tooltip title={'Upgrade your plan'} aria-label={'Upgrade your plan'} >
                                          <Fab
                                            color="primary"
                                            aria-label={'Upgrade your plan'}
                                            size="small"
                                            onClick={createNewIntegration}
                                          >
                                            <ShoppingCartRoundedIcon style={{marginLeft: 0, color: primaryColour[900] }} />
                                          </Fab>
                                        </Tooltip>
                                      ) : (
                                        <Tooltip title={ updating ? 'Updating' : 'Create copy'} aria-label={ updating ? 'Updating' : 'Create copy'}>
                                          <Fab
                                            color="primary"
                                            aria-label={ updating ? 'Updating' : 'Create copy'}
                                            size="small"
                                            onClick={updating ? null : () => handleCopyCreation(item, key)}
                                          >
                                            { updating && state.integrationDataOptions?.selectedIntegration === key ? <CircularProgress color='inherit' size={15} /> : <FileCopyRounded /> }
                                          </Fab>
                                        </Tooltip>
                                      )
                                  }
                                  <Tooltip title={ 'Remove' } aria-label={ 'Remove' }>
                                    <Fab
                                      color="primary"
                                      aria-label={ 'Remove' }
                                      size="small"
                                      onClick={updating || removing ? null : () => handleClickOpen(key)}
                                    >
                                      { <DeleteForeverRounded style={{color: '#bf3a37'}} /> }
                                    </Fab>
                                  </Tooltip>
                                </Grid>
                              )
                            }
                          </Grid>
                        </AdminPanels>
                      </Grid>
                    )
                  })
                }
              </>
            ) :
            <Grid item xm={12} md={12} lg={12}>
              <Typography variant="h5">Welcome to Calcumate! To get started:</Typography>
              <Typography variant="body1" style={{color:"#00437C", marginTop:"8px", marginBottom:"8px"}}>
                1. Click "+ BUILD CALCULATOR"<br/>
                2. Follow the steps to create the calculator you need<br/>
                3. Happy? Click to start your subscription and we’ll supply the calculator code snippet for your website.
              </Typography>
              <Typography variant="h6" component="p" style={{marginTop:20}}>Ready to start?</Typography>
              <Button component={Link} to="/admin/my-calcumate/new" className={classes.addNewIntegration} variant="contained" color="secondary" size="large" startIcon={<AddRounded />}>BUILD CALCULATOR</Button>
              {/* <Button disabled={state.signedInUser.data.user && state.signedInUser.data.user.integrations && state.signedInUser.data.user.integrations.length > 0} component={Link} to="/admin/my-calcumate/new" className={classes.addNewIntegration} variant="contained" color="secondary" size="large" startIcon={<AddRounded />}>Create calculator</Button> */}
            </Grid>
          }
        </Grid>
        )
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Removing integration:"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to permanently remove this integration?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button size="large" onClick={removeIntegration} color="primary" autoFocus>
            { removing ? <CircularProgress color='inherit' size={15} /> : 'Yes, Remove' }
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!error}
        autoHideDuration={3000}
        onClose={() => setError(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        message={error}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Box className={classes.panelHelpBar}>
        <AdminPanels
          theHeader={false}
          headerAction={false}
          fabCodeAction={false}
          fabCloneAction={false}
          fabEditAction={false}
          fabDeleteAction={false}
          panelTheme="dark"
        >
          <Typography variant="h5">Need assistance?</Typography>
          <Typography variant="body2">For further assistance, please <Link to="/admin/support">contact us</Link>. We're here to help!</Typography>
        </AdminPanels>
      </Box>
      <MoreIntegrations
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </Fragment>
  );
}

export default MyCalcumate
