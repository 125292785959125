export const preparePresets = (state) => {
  const prepared = state.integrationData.integrationPresets.map((preset) => {
    const updatedPreset = updateIndexes(preset, state);
    return {
     ...preset,
     items: getItemIds(updatedPreset.itemsAssigned, state),
     itemsAssigned: updatedPreset.itemsAssigned
    }
 });
 return prepared;
};

// itemIndices is an array of indices from state.integrationData.itemLibrary.
// We just need to retrieve the items for the list of indices
const getItemIds = (itemIndices, state) => {
  const { itemLibrary, integrationActiveLanguage } = state.integrationData;

  const selectedItems = itemIndices
    .map((index) => itemLibrary.find((_, i) => i === index))
    .map((item, key) => ({ id: item.id, name: item[integrationActiveLanguage], sort: key }));

  return selectedItems;
};

const updateIndexes = (category, state) => {
  const itemsAssigned = []
  if (!category.items) return category;
  for (let i = 0; i < category.items.length; i++) {
    const index = state.integrationData.itemLibrary.findIndex(el => el.id === category.items[i].id);
    if (index !== -1)
      itemsAssigned.push(index)
  }
  category.itemsAssigned = itemsAssigned;
  return category
}

export const prepareCategories = (state) => {
  const prepared = state.integrationData.integrationCategories.map(
    (category, index) => {
      const updatedCategory = updateIndexes(category, state);
      return {
        ...category,
        items: getItemIds(updatedCategory.itemsAssigned, state),
        itemsAssigned: updatedCategory.itemsAssigned,
        sort: index
      }
    }
  );
  return prepared;
};

export const freeAccounts = [
  'membership@selfstorage.com.au',
  'happly@calcumate.co',
  process.env.REACT_APP_DEV_USER,
  'brian+test@storagely.io',
  'tgardner@quikstor.com',
  'michael@keepitsimplestorage.com',
];

export const calculateProgressivePrice = (prices, number) => {
  let price = 0;
  let prev;
  // Calculate final price
  prices.forEach((value, key) => {
    if (key[0] > number) { // Skip if number does not include into range
      return;
    }
    const cN = key[1] <= number ? key[1] : number;
    prev ? 
      price = price + (value * (cN - prev[1])) : // Calculate Price for all but not first range
      price = price + (value * cN); // Price for first range
    prev = key;
  });
  return price;
}

export const isPriceProgressive = (prices) => prices && prices.constructor === Map;

export let volume = ({
  width,
  height,
  depth
}) => width * height * depth;

//let volumeLb = ((width * depth * height) / 28317).toFixed(2);
export const volumeDisplayText = (isMetric, volume) => round(volume) + (isMetric ? 'm' : 'ft');

export const round = price => {
  if (typeof(price) === 'number') {
    return price.toFixed(2).replace(/\.00$/, '');
  }
  return price;
};

export const pricingPlanFeature = (feature) => {
  return feature?.name || feature;
}

export const clearPath = (str) => str.replace(/\/$/, '');