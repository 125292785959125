import React from "react";
import { Grid, Hidden, Typography, Button } from "@material-ui/core";
import CopyIcon from "../../../assets/img/Copy.webp";
import { makeStyles } from "@material-ui/core/styles";
import {
  blueColor,
  transparentBlue,
  secondaryColours,
  whiteColor,
  blackColor,
} from "assets/jss/material-dashboard-react";
import SendDataIcon from "../../../assets/img/partners/send-data.svg";

const styles = (theme) => ({
  image: {
    maxWidth: 395,
  },
  section3: {
    position: "relative",
    backgroundColor: whiteColor,
    marginBottom: 56,
    "& button": {
      backgroundColor: blueColor,
      color: whiteColor,
      fontSize: 24,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "28px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: secondaryColours[0],
      },
    },
  },
  readyWrap: {
    paddingTop: 56,
    paddingBottom: 56,
    paddingLeft: 32,
    paddingRight: 32,
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    maxWidth: 1190,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  readyContainer: {
    backgroundColor: transparentBlue,
    width: '100%'
  },
  rightColumn: {
    maxWidth: 516,
    gap: 32,
    "& p": {
      marginTop: 32,
      marginBottom: 32,
    },
  },
  leftColumn: {
    display: "flex",
    justifyContent: "flex-end",
    paddingLeft: 32,
    alignItems: 'center',
  },
  aboutCalcumate: {
    maxWidth: 516,
    paddingRight: 32,
    "& p": {
      marginTop: 32,
      marginBottom: 32,
    },
  },
  aboutCalcumateWrap: {
    backgroundColor: "white",
    paddingTop: 56,
    paddingBottom: 168,
  },
  aboutCalcumateWrap: {
    maxWidth: 1190,
  },
  title: {
    color: blueColor,
    fontSize: 24,
    fontWeight: 500,
    lineHeight: "28px",
  },
  text: {
    color: blackColor,
    fontSize: 24,
    fontWeight: 400,
    lineHeight: "28px",
  },
  aboutCalcumateContainer: {
    paddingLeft: 32,
    backgroundColor: transparentBlue,
    paddingTop: 56,
    paddingBottom: 56,
  },
  aboutCalcumateWrap: {
    maxWidth: 1190,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  needHelpWrap: {
    padding: '56px 0px',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 1190,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 32,
    paddingRight: 32,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    "& .title": {
      color: blueColor,
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "28px",
    },
    "& .text": {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: "28px",
      marginTop: 32,
    },
    "& a": {
      color: whiteColor,
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "28px",
    },
    '& > div': {
      maxWidth: 516
    }
  },
});

const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();

  return (
    <Grid className={classes.section3}>
      <Grid className={classes.readyContainer}>
        <Grid container className={classes.readyWrap}>
          <Grid xs={12} sm={6} className={classes.rightColumn}>
            <Typography component={"h4"} className={classes.title}>
              Ready to Learn More?
            </Typography>
            <Typography component={"p"} className={classes.text}>
              Explore Calcumate’s enhanced features and discover how we can help
              your facility improve user experience and drive more bookings.
              Transition, Sales and Support Enquiries:{" "}
              <a href="mailto:info@calcumate.co">info@calcumate.co</a>
            </Typography>
            <div>
              <Button variant="contained">Request a Demo</Button>
            </div>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} sm={6} className={classes.leftColumn}>
              <img className={classes.image} src={CopyIcon} alt="Copy" />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Grid className={classes.needHelpWrap}>
        <Grid>
          <Typography component={"h3"} className="title">
            Need Help?
          </Typography>
          <Typography component={"p"} className="text">
            If you’re an existing Mini Storage Calculator customer and have
            questions about your account or the transition, feel free to
            reach out!
          </Typography>
          <a href="mailto:info@calcumate.co">info@calcumate.co</a>
        </Grid>
      </Grid>
      <Grid className={classes.aboutCalcumateContainer}>
        <Grid className={classes.aboutCalcumateWrap}>
          <Grid className={classes.aboutCalcumate}>
            <Typography component={"h4"} className={classes.title}>
              About Calcumate
            </Typography>
            <Typography component={"p"} className={classes.text}>
              Calcumate is the world’s leading 3D storage calculator and size
              guide. Trusted by over 5,000 facilities in more than 30 countries,
              Calcumate helps storage operators boost customer engagement and
              increase conversion rates.
            </Typography>
            <div>
              <Button variant="contained">Learn more</Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
